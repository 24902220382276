import { PageId } from '../../app/shared/enums/page-id.enum';

/**
 * En este fichero se definen las rutas de la aplicación y sus traducciones.
 * Por defecto se navegará a la primera ruta de las posibles en cada idioma.
 * Se le ha añadido un * a las rutas relacionadas con tickets para poder identificar
 * el producto a navegar ya que existe un solapamiento entre las rutas
 * de las distribuidoras y la página de detalle (/tickets, /tickets/tours-from-barcelona...).
 */
export const i18nRoutes = {
  [PageId.About]: {
    ca: ['/ca/sobre-nosaltres'],
    en: ['/en/about-us', '/about-us'],
    es: ['/es/sobre-nosotros'],
    it: ['/it/riguardo-a-noi'],
    fr: ['/fr/a-propos-de-nous'],
    de: ['/de/uber-uns'],
  },
  [PageId.AppHolaBarcelona]: {
    ca: ['/ca/app-hola-barcelona'],
    en: ['/en/hola-barcelona-app', '/hola-barcelona-app'],
    es: ['/es/app-hola-barcelona'],
    it: ['/it/hola-barcelona-app'],
    fr: ['/fr/hola-barcelona-app'],
    de: ['/de/hola-barcelona-app'],
  },
  [PageId.Cart]: {
    ca: ['/ca/cart'],
    en: ['/en/cart', '/cart'],
    es: ['/es/cart'],
    it: ['/it/cart'],
    fr: ['/fr/cart'],
    de: ['/de/cart'],
  },
  // [PageId.CatalunyaBusTuristic]: {},
  [PageId.Checkout]: {
    ca: ['/ca/cart/payment'],
    en: ['/en/cart/payment', '/cart/payment'],
    es: ['/es/cart/payment'],
    it: ['/it/cart/payment'],
    fr: ['/fr/cart/payment'],
    de: ['/de/cart/payment'],
  },
  [PageId.Contact]: {
    ca: ['/ca/contacte'],
    en: ['/en/contact-us', '/contact-us'],
    es: ['/es/contacto'],
    it: ['/it/contatto'],
    fr: ['/fr/contact'],
    de: ['/de/kontakt'],
  },
  [PageId.ContactMobile]: {
    ca: ['/ca/contacte-mobile'],
    en: ['/en/contact-us-mobile', '/contact-us-mobile'],
    es: ['/es/contacto-mobile'],
    it: ['/it/contatto-mobile'],
    fr: ['/fr/contact-mobile'],
    de: ['/de/kontakt-mobile'],
  },
  [PageId.Cookies]: {
    ca: ['/ca/politica-de-cookies'],
    en: ['/en/cookies-policy', '/cookies-policy'],
    es: ['/es/politica-de-cookies'],
    it: ['/it/cookies-politica'],
    fr: ['/fr/politique-de-cookies'],
    de: ['/de/cookies-politik'],
  },
  [PageId.Discover]: {
    ca: ['/ca/descobreix-hola-barcelona'],
    en: ['/en/discover-hola-barcelona', '/discover-hola-barcelona'],
    es: ['/es/descubre-hola-barcelona'],
    it: ['/it/scopri-hola-barcelona'],
    fr: ['/fr/decouvrir-hola-barcelona'],
    de: ['/de/entdecken-sie-hola-barcelona'],
  },
  [PageId.DiscoverCatalunya]: {
    ca: ['/ca/tickets/tours-from-barcelona'],
    en: ['/en/tickets/tours-from-barcelona'],
    es: ['/es/tickets/tours-from-barcelona'],
    it: ['/it/tickets/tours-from-barcelona'],
    fr: ['/fr/tickets/tours-from-barcelona'],
    de: ['/de/tickets/tours-from-barcelona'],
  },
  [PageId.Error]: {
    ca: ['/ca/error'],
    en: ['/en/error'],
    es: ['/es/error'],
    it: ['/it/error'],
    fr: ['/fr/error'],
    de: ['/de/error'],
  },
  [PageId.Faq]: {
    ca: ['/ca/preguntes-frequents'],
    en: ['/en/faq', '/faq'],
    es: ['/es/preguntas-frequentes'],
    it: ['/it/faq'],
    fr: ['/fr/questions-common'],
    de: ['/de/hgf'],
  },
  [PageId.FaqMobile]: {
    ca: ['/ca/preguntes-frequents-mobile'],
    en: ['/en/faq-mobile', '/faq-mobile'],
    es: ['/es/preguntas-frequentes-mobile'],
    it: ['/it/faq-mobile'],
    fr: ['/fr/questions-common-mobile'],
    de: ['/de/hgf-mobile'],
  },
  [PageId.Home]: {
    ca: ['/ca'],
    en: ['/en', '/'],
    es: ['/es'],
    it: ['/it'],
    fr: ['/fr'],
    de: ['/de'],
  },
  [PageId.HowToDiscover]: {
    ca: ['/ca/com-descobrir'],
    en: ['/en/how-to-discover', '/how-to-discover'],
    es: ['/es/como-descubrir'],
    it: ['/it/come-scoprire'],
    fr: ['/fr/comment-decouvrir'],
    de: ['/de/verkehrsmittel'],
  },
  [PageId.Legal]: {
    ca: ['/ca/avis-legal'],
    en: ['/en/legal-notice', '/legal-notice'],
    es: ['/es/aviso-legal'],
    it: ['/it/aviso-legale'],
    fr: ['/fr/notice-legale'],
    de: ['/de/impressum'],
  },
  [PageId.LegalAdviceSignup]: {
    ca: ['/ca/termes-i-condicions-hola-barcelona'],
    en: [
      '/en/terms-and-conditions-hola-barcelona',
      '/terms-and-conditions-hola-barcelona',
    ],
    es: ['/es/terminos-y-condiciones-hola-barcelona'],
    it: ['/it/terms-and-conditions-hola-barcelona'],
    fr: ['/fr/terms-and-conditions-hola-barcelona'],
    de: ['/de/terms-and-conditions-hola-barcelona'],
  },
  [PageId.MouteAmbHola]: {
    ca: ['/ca/moute-amb-hola-barcelona'],
    en: [
      '/en/get-around-with-hola-barcelona',
      '/get-around-with-hola-barcelona',
    ],
    es: ['/es/muevete-con-hola-barcelona'],
    it: ['/it/muoviti-con-hola-barcelona'],
    fr: ['/fr/deplacez-vous-avec-hola-barcelona'],
    de: ['/de/bewegen-sie-sich-mit-hola-barcelona'],
  },
  [PageId.Recover]: {
    ca: ['/ca/cupo/recuperar'],
    en: ['/en/voucher/recover', '/voucher/recover'],
    es: ['/es/cupon/recuperar'],
    it: ['/it/voucher/recuperare'],
    fr: ['/fr/coupon/recuperer'],
    de: ['/de/voucher/genesen'],
  },
  [PageId.Sitemap]: {
    ca: ['/ca/mapa-web'],
    en: ['/en/site-map', '/site-map'],
    es: ['/es/mapa-web'],
    it: ['/it/site-map'],
    fr: ['/fr/carte-web'],
    de: ['/de/site-map'],
  },
  [PageId.TermsAndConditions]: {
    ca: ['/ca/condicions-generals'],
    en: ['/en/conditions', '/conditions'],
    es: ['/es/condiciones-generales'],
    it: ['/it/condizioni'],
    fr: ['/fr/conditions'],
    de: ['/de/bedingungen'],
  },
  [PageId.Thanks]: {
    ca: ['/ca/thanks'],
    en: ['/en/thanks', '/thanks'],
    es: ['/es/thanks'],
    it: ['/it/thanks'],
    fr: ['/fr/thanks'],
    de: ['/de/thanks'],
  },
  [PageId.Ticket]: {
    ca: [
      '/ca/tickets*',
      '/ca/tickets/tours-from-barcelona*',
      '/ca/packs-de-transports*',
    ],
    en: [
      '/en/tickets*',
      '/en/tickets/tours-from-barcelona*',
      '/en/transport-deals*',
      '/tickets*',
      '/tickets/tours-from-barcelona*',
      '/transport-deals*',
    ],
    es: [
      '/es/tickets*',
      '/es/tickets/tours-from-barcelona*',
      '/es/packs-de-transportes*',
    ],
    it: [
      '/it/tickets*',
      '/it/tickets/tours-from-barcelona*',
      '/it/pacchetti-mezzi-di-transporto*',
    ],
    fr: [
      '/fr/tickets*',
      '/fr/tickets/tours-from-barcelona*',
      '/fr/packs-de-titres-de-transport*',
    ],
    de: [
      '/de/tickets*',
      '/de/tickets/tours-from-barcelona*',
      '/de/beforderungspakete*',
    ],
  },
  [PageId.Tickets]: {
    ca: ['/ca/compra-bitllets'],
    en: ['/en/buy-tickets', '/buy-tickets'],
    es: ['/es/comprar-billetes'],
    it: ['/it/acquistare-biglietti'],
    fr: ['/fr/acheter-tickets'],
    de: ['/de/tickets-kaufen'],
  },
  [PageId.TransportDealsLanding]: {
    ca: ['/ca/packs-de-transports'],
    en: ['/en/transport-deals', '/transport-deals'],
    es: ['/es/packs-de-transportes'],
    it: ['/it/pacchetti-mezzi-di-transporto'],
    fr: ['/fr/packs-de-titres-de-transport'],
    de: ['/de/beforderungspakete'],
  },
  [PageId.Unsubscribe]: {
    ca: ['/ca/baixa-correu'],
    en: ['/en/unsubscribe-email', '/unsubscribe-email'],
    es: ['/es/baja-correo'],
    it: ['/it/unsubscribe-email'],
    fr: ['/fr/unsubscribe-email'],
    de: ['/de/unsubscribe-email'],
  },
  // [PageId.UnsubscribeMessage]: {},
};
