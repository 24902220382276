import { Injectable, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { AppLanguage } from '../enums/app-language.enum';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly state = signal<AppLanguage>(AppLanguage.EN);
  private readonly set$ = new Subject<AppLanguage>();

  public language = this.state.asReadonly();
  public locale = computed(() => this.getLocaleId(this.language()));

  constructor() {
    this.set$
      .pipe(takeUntilDestroyed())
      .subscribe((language) => this.state.set(language));
  }

  public setLanguage(language: AppLanguage): void {
    if (language) {
      this.set$.next(language);
    }
  }

  private getLocaleId(language: AppLanguage): string {
    return `${language}-${language.toUpperCase()}`;
  }
}
