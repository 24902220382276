import { Injectable, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { i18nRoutes } from '../../../assets/i18n/routes.i18n';
import { AppLanguage } from '../enums/app-language.enum';
import { PageId } from '../enums/page-id.enum';
import { StrapiSuperProductsGroupCode } from '../enums/strapi-super-products-group-code.enum';
import { SuperProduct } from '../models/super-product.interface';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly router = inject(Router);
  private readonly languageService = inject(LanguageService);
  private readonly appLanguage = this.languageService.language;

  public superProductsGroupPageId: Map<StrapiSuperProductsGroupCode, PageId> =
    new Map([
      [StrapiSuperProductsGroupCode.Home, PageId.Home],
      [StrapiSuperProductsGroupCode.Tours, PageId.DiscoverCatalunya],
      [StrapiSuperProductsGroupCode.Packs, PageId.TransportDealsLanding],
    ]);

  public aboutUrl = computed(
    () => i18nRoutes[PageId.About][this.appLanguage()][0],
  );
  public appHolaBarcelonaUrl = computed(
    () => i18nRoutes[PageId.AppHolaBarcelona][this.appLanguage()][0],
  );
  public cartUrl = computed(
    () => i18nRoutes[PageId.Cart][this.appLanguage()][0],
  );
  public checkoutUrl = computed(
    () => i18nRoutes[PageId.Checkout][this.appLanguage()][0],
  );
  public contactUrl = computed(
    () => i18nRoutes[PageId.Contact][this.appLanguage()][0],
  );
  public contactMobileUrl = computed(
    () => i18nRoutes[PageId.ContactMobile][this.appLanguage()][0],
  );
  public cookiesUrl = computed(
    () => i18nRoutes[PageId.Cookies][this.appLanguage()][0],
  );
  public discoverUrl = computed(
    () => i18nRoutes[PageId.Discover][this.appLanguage()][0],
  );
  public discoverCatalunyaUrl = computed(
    () => i18nRoutes[PageId.DiscoverCatalunya][this.appLanguage()][0],
  );
  public faqUrl = computed(() => i18nRoutes[PageId.Faq][this.appLanguage()][0]);
  public faqMobileUrl = computed(
    () => i18nRoutes[PageId.FaqMobile][this.appLanguage()][0],
  );
  public homeUrl = computed(
    () => i18nRoutes[PageId.Home][this.appLanguage()][0],
  );
  public howToDiscoverUrl = computed(
    () => i18nRoutes[PageId.HowToDiscover][this.appLanguage()][0],
  );
  public legalUrl = computed(
    () => i18nRoutes[PageId.Legal][this.appLanguage()][0],
  );
  public legalAdviceSignupUrl = computed(
    () => i18nRoutes[PageId.LegalAdviceSignup][this.appLanguage()][0],
  );
  public mouteAmbHolaUrl = computed(
    () => i18nRoutes[PageId.MouteAmbHola][this.appLanguage()][0],
  );
  public recoverUrl = computed(
    () => i18nRoutes[PageId.Recover][this.appLanguage()][0],
  );
  public sitemapUrl = computed(
    () => i18nRoutes[PageId.Sitemap][this.appLanguage()][0],
  );
  public termsAndConditionsUrl = computed(
    () => i18nRoutes[PageId.TermsAndConditions][this.appLanguage()][0],
  );
  public ticketUrl = computed(
    // pagina de producto
    () => i18nRoutes[PageId.Ticket][this.appLanguage()][0],
  );
  public ticketsUrl = computed(
    () => i18nRoutes[PageId.Tickets][this.appLanguage()][0],
  );
  public unsubscribeUrl = computed(
    () => i18nRoutes[PageId.Unsubscribe][this.appLanguage()][0],
  );
  public transportDealsLandingUrl = computed(
    () => i18nRoutes[PageId.TransportDealsLanding][this.appLanguage()][0],
  );

  public getUrl(pageId: PageId, language?: AppLanguage): string {
    return pageId
      ? i18nRoutes[pageId][language || this.appLanguage()][0]
      : PageId.Home;
  }

  public navigateByUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  public navigateToPage(pageId: PageId, language?: AppLanguage): void {
    this.router.navigate([this.getUrl(pageId, language)]);
  }

  public navigateToSuperProductsGroup(
    superProductsGroupCode: StrapiSuperProductsGroupCode,
  ): void {
    const pageId =
      this.superProductsGroupPageId.get(superProductsGroupCode) || PageId.Home;
    this.navigateToPage(pageId);
  }

  public navigateToSuperProduct(superProduct: SuperProduct): void {
    if (!superProduct?.slug) {
      return;
    }
    this.navigateByUrl(`/${this.getUrl(PageId.Ticket)}/${superProduct?.slug}`);
  }
}
