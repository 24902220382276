export enum PageId {
  About = 'about',
  AppHolaBarcelona = 'app_hola_barcelona',
  Cart = 'cart',
  // CatalunyaBusTuristic = 'catalunya_bus_turistic',
  Checkout = 'checkout',
  Contact = 'contact',
  ContactMobile = 'contact_mobile_page',
  Cookies = 'cookies',
  Discover = 'discover',
  DiscoverCatalunya = 'discover_catalunya',
  Error = 'error',
  Faq = 'faq',
  FaqMobile = 'faq_mobile_page',
  Home = 'home',
  HowToDiscover = 'how_to_discover',
  Legal = 'legal',
  LegalAdviceSignup = 'legal_advice_signup_page',
  MouteAmbHola = 'moute_amb_hola',
  // Pack = 'pack',
  Recover = 'recover',
  Sitemap = 'sitemap',
  TermsAndConditions = 'terms_and_conditions',
  Thanks = 'thanks',
  Ticket = 'ticket',
  Tickets = 'tickets_page',
  TransportDealsLanding = 'transport_deals_landing',
  Unsubscribe = 'unsubscribe',
  // UnsubscribeMessage = 'unsubscripbe_message_page',
}
